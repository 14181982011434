import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { images } from '../../Assets/images'
import './GlamWelcome.css';

const GlamWelcome: React.FC = () => {

  function gotoDashboard(){
    alert("dashboard");
  }

  return (
    <main 
  className="flex flex-col bg-white text-stone-800" 
  style={{
    backgroundImage: `url(${images.LogoBg})`, // Correct format for backgroundImage
    backgroundColor: 'black', // Black color as a base background
    height: '100vh'
  }}
>
      <section className="flex relative flex-col set justify-center items-center px-20 py-36 w-full min-h-[1080px] max-md:px-5 max-md:py-24 max-md:max-w-full">
        <Box className="welcome-card">
      <Typography variant="h1" className="welcome-title">
        <span className="font-light">Glam</span>
        <span className="font-bold">9</span>
      </Typography>
      <Typography variant="h2" className="welcome-subtitle">
        You're All Set
      </Typography>
      <Typography variant="body1" className="welcome-description">
        Welcome to the best tool for managing<br />your Business
      </Typography>
      <Typography variant="body1" className="welcome-info">
        Glam9 BizPro is designed to help you keep peace. Manage team calendars,
        create shifts, process payments, run reports, and check stock levels—all
        from the front desk.
        <br /><br />
        And, when you're on the go? Use Glam9 Biz on your mobile devices to
        access core features and keep tabs on your business from anywhere.
      </Typography>
      <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                  marginLeft: "20px",
                  marginTop: "10px"
                }}
                onClick={ gotoDashboard }
              >
                Save
              </Button>
    </Box>
      </section>
    </main>
  );
};
export default GlamWelcome;
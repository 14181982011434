import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Set from './pages/Set';
const App: React.FC = () => (
  <Router>
    <div style={{ minHeight: "80vh" }}>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/set" element={<Set />} />
      </Routes>
    </div>
  </Router>
);
export default App;
import { validateEmail, sendEmailVerificationOTP, verifyOTP, registerVendor, getVendorProfileData, loginVendor, forgotPassword, vendorCategories, commonDataList } from '../../src/api/vendor';
import { ValidateEmailResponse, emailSendOTPResponse, loginVendorReqData, registerVendorReqData, registerVendorResponse, vendorCategoriesResponse, vendorLoginDataResponse, vendorProfileDataResponse } from '../../src/api/types/vendor';
import { ApiResponse, commonDataListResponse } from '../../src/api/types/common';
export const VendorAPI = () => {
  // Validate Email
  const validateEmailAPICall = async (email: string): Promise<ValidateEmailResponse> => {
    try {
      const response: ValidateEmailResponse = await validateEmail(email);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ValidateEmailResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ValidateEmailResponse;
    }
  };
  // Send OTP
  const sendEmailOTP = async (email: string): Promise<emailSendOTPResponse> => {
    try {
      const response: emailSendOTPResponse = await sendEmailVerificationOTP(email);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as emailSendOTPResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as emailSendOTPResponse;
    }
  };
  // Verify OTP
  const verifyOTPAPICall = async (email: string): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await verifyOTP(email);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse;
    }
  };
  // Register Vendor
  const registerVendorAPICall = async (data: registerVendorReqData): Promise<registerVendorResponse> => {
    try {
      const response: registerVendorResponse = await registerVendor(data);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as registerVendorResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as registerVendorResponse;
    }
  };
  // Vendor Profile Data Response
  const vendorProfileDataAPICall = async (): Promise<vendorProfileDataResponse> => {
    try {
      const response: vendorProfileDataResponse = await getVendorProfileData();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as vendorProfileDataResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as vendorProfileDataResponse;
    }
  };
  // Login Vendor
  const loginVendorAPICall = async (data: loginVendorReqData): Promise<vendorLoginDataResponse> => {
    try {
      const response: vendorLoginDataResponse = await loginVendor(data);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as vendorLoginDataResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as vendorLoginDataResponse;
    }
  };
  // Forgot Password
  const forgotPasswordAPICall = async (email: string): Promise<ApiResponse> => {
    try {
      const response: ApiResponse = await forgotPassword(email);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse;
    }
  };
  // Vendor Categories
  const vendorCategoriesAPICall = async (): Promise<vendorCategoriesResponse> => {
    try {
      const response: vendorCategoriesResponse = await vendorCategories()
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as vendorCategoriesResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as vendorCategoriesResponse;
    }
  };
  // Common Data List
  const commonDataListAPICall = async (): Promise<commonDataListResponse> => {
    try {
      const response: commonDataListResponse = await commonDataList()
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as commonDataListResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as commonDataListResponse;
    }
  };
  return {
    validateEmailAPICall,
    sendEmailOTP,
    verifyOTPAPICall,
    registerVendorAPICall,
    vendorProfileDataAPICall,
    loginVendorAPICall,
    forgotPasswordAPICall,
    vendorCategoriesAPICall,
    commonDataListAPICall
  };
};

import React, { useState } from 'react';
import {
  Grid, Paper, Box,
  Typography, TextField, Button, Divider,
  IconButton, LinearProgress, InputAdornment, FormControl,
  InputLabel, Select, MenuItem, FormControlLabel, FormHelperText,
  Checkbox, Radio, RadioGroup, Switch, Dialog,
  DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import './login.css';
import { images } from '../../Assets/images'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
// Interface
import { loginVendorReqData, registerVendorReqData, registerVendorResponse, registrationValidationErrors, vendorBusinessData, vendorCategoriesResData, vendorCategoriesResponse, vendorLoginDataResponse, vendorProfileData, vendorProfileInfo } from '../../../src/api/types/vendor';
import Flag from 'react-world-flags';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { VendorAPI } from '../../../src/hooks/vendor';
import toast from 'react-hot-toast';
import { REGISTRATION_VIEW_TYPES } from '../../../src/api/types/vendor';
const Login: React.FC = () => {
  // Hooks API Call
  const {
    validateEmailAPICall,
    sendEmailOTP,
    verifyOTPAPICall,
    registerVendorAPICall,
    vendorProfileDataAPICall,
    loginVendorAPICall,
    forgotPasswordAPICall,
    vendorCategoriesAPICall,
    commonDataListAPICall
  } = VendorAPI();
  const navigate = useNavigate();
  const goToFinalPage = () => {
    navigate('/set')
  }
  // States
  const [isNewProfile, setIsNewProfile] = useState(false)
  const [viewType, setViewType] = useState<REGISTRATION_VIEW_TYPES>(REGISTRATION_VIEW_TYPES.LOGIN);
  const [vendorProfileData, setVendorProfileData] = useState<vendorProfileData>({
    email: "",
    password: "",
    otp: ""
  })
  const [businessData, setBusinessData] = useState<any>();
  const [vendorProfileInfo, setVendorProfileInfo] = useState<vendorProfileInfo>()
  const [formErrorData, setFormErrorData] = useState<registrationValidationErrors>({
    email: ""
  })
  const [verificationCode, setVerificationCode] = useState<number>();
  const [showPassword, setShowPassword] = useState(false);
  const [vendorCategoriesData, setVendorCategoriesData] = useState<vendorCategoriesResData[]>();
  const [selectedCategories, setSelectedCategories] = useState<any>([])
  const [businessTypes, setBusinessTypes] = useState<any>([])
  // Functions
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  // API Calls
  const checkEmail = async () => {
    setFormErrorData({ ...formErrorData, email: "" });
    if (vendorProfileData.email === '') {
      setFormErrorData({ ...formErrorData, email: "Please enter Your Email Id" });
    } else if (!isValidEmail(vendorProfileData.email)) {
      setFormErrorData({ ...formErrorData, email: "Invalid Email. Please enter proper email" });
    } else {
      const validateEmailRequestResult = await validateEmailAPICall(vendorProfileData.email);
      if (validateEmailRequestResult.success) {
        setIsNewProfile(validateEmailRequestResult.data?.is_available === 1 ? true : false)
        if (validateEmailRequestResult.data?.is_available === 1 || validateEmailRequestResult.data?.email_verified === 1) {
          setViewType(REGISTRATION_VIEW_TYPES.PASSWORD)
        } else if (validateEmailRequestResult.data?.is_available === 2 || validateEmailRequestResult.data?.email_verified === 2) {
          vendorEmailSendOTP()
        } else if (validateEmailRequestResult.data?.is_approved === 2) {
          toast.error("Your account has been suspended please contact administrator for more details.")
        } else {
          toast.error("Error while validate email")
        }
      } else {
        toast.error("Error while validate email")
      }
    }
  }
  // Email Send OTP
  const vendorEmailSendOTP = async () => {
    setVerificationCode(0)
    setVendorProfileData({ ...vendorProfileData, otp: "" })
    const result = await sendEmailOTP(vendorProfileData.email);
    if (result.success) {
      setVerificationCode(result.data?.otp)
      setViewType(REGISTRATION_VIEW_TYPES.OTP_VERIFICATION)
    } else {
      toast.error("Error while Send OTP")
    }
  }
  const handleVerificationCodeChange = async (otp: any) => {
    setVendorProfileData({ ...vendorProfileData, otp: otp })
    verifyOTP(otp)
  }
  const verifyOTP = async (otp: string) => {
    setFormErrorData({ ...formErrorData, otp: "" })
    if (otp.length === 6 && parseInt(otp) === verificationCode) {
      const result = await verifyOTPAPICall(vendorProfileData.email);
      setViewType(REGISTRATION_VIEW_TYPES.REGISTRATION_PASSWORD)
      if (result.success) {
        setViewType(REGISTRATION_VIEW_TYPES.REGISTRATION_PASSWORD)
      } else {
        toast.error("Error while verify OTP")
      }
    }
    if (otp.length === 6 && parseInt(otp) !== verificationCode) {
      setFormErrorData({ ...formErrorData, otp: "Invalid OTP" })
    }
  }
  // Register Vendor
  const registerVendorFunc = async () => {
    const reqData: registerVendorReqData = {
      email: vendorProfileData.email,
      password: vendorProfileData.password,
      device_name: navigator.userAgent,
      device_type: 3,
      device_os: getDeviceOS(),
      device_token: generateUUID(),
      device_uuid: generateUUID(),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    const result: registerVendorResponse = await registerVendorAPICall(reqData);
    if (result.success) {
      if (result.data) {
        localStorage.setItem('vendor_register_data', JSON.stringify(result.data))
        localStorage.setItem('vendor_auth_token', result.data?.token)
        vendorProfileDataFunc()
      }
    } else {
      toast.error("Error while register vendor")
    }
  }
  // Device Functions
  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  const getDeviceOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent)) return "Windows Phone";
    if (/android/i.test(userAgent)) return "Android";
    if (/iPad|iPhone|iPod/.test(userAgent)) return "iOS";
    if (/Macintosh|MacIntel|MacPPC|Mac68K|MacOS/.test(userAgent)) return "MacOS";
    if (/Win32|Win64|Windows|WinCE/.test(userAgent)) return "Windows";
    if (/Linux/.test(userAgent)) return "Linux";

    return "Unknown";
  }
  // Login Vendor
  const loginVendorFunc = async () => {
    const reqData: loginVendorReqData = {
      email: vendorProfileData.email,
      password: vendorProfileData.password,
      device_name: navigator.userAgent,
      device_type: 3,
      device_id: generateUUID(),
      device_os: getDeviceOS(),
      device_token: generateUUID(),
      device_uuid: generateUUID(),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    const result: vendorLoginDataResponse = await loginVendorAPICall(reqData);
    if (result.success) {
      if (result.data) {
        setViewType(REGISTRATION_VIEW_TYPES.BUSINESS_CATEGORIES)
        localStorage.setItem('vendor_login_data', JSON.stringify(result.data))
        localStorage.setItem('vendor_auth_token', result.data?.token)
        vendorProfileDataFunc()
      }
    } else {
      toast.error("Error while register vendor")
    }
  }
  // Vendor Profile Data
  const vendorProfileDataFunc = async () => {
    const result = await vendorProfileDataAPICall()
    if (result.success && result.data) {
      console.log(result.data, "result.data")
      setVendorProfileInfo(result.data)
      vendorCategoriesFunc();
      commonDataListFunc();
      setViewType(REGISTRATION_VIEW_TYPES.BUSINESS_CATEGORIES)
    } else {
      toast.error("Error while fetching vendor profile data")
    }
  }
  // Vendor Forgot Password
  const vendorForgotPasswordFunc = async () => {
    const result = await forgotPasswordAPICall(vendorProfileData.email)
    if (result.success && result.data) {
      toast.success(result.message)
      setViewType(REGISTRATION_VIEW_TYPES.LOGIN)
    } else {
      toast.error("Error while fetching vendor profile data")
    }
  }
  // Vendor Categories
  const vendorCategoriesFunc = async () => {
    const result: any = await vendorCategoriesAPICall()
    if (result.success && result.data) {
      const categoriesInfo = result.data
      setVendorCategoriesData(categoriesInfo)
    } else {
      toast.error("Error while fetching vendor profile data")
    }
  }

  // Common Data List - commonDataListAPICall
  const commonDataListFunc = async () => {
    const result = await commonDataListAPICall()
    if (result.success && result.data) {
      console.log(result.data, "COMMON DATA LIST RESPONSE");
      setBusinessTypes(result.data.salon_types)

    } else {
      toast.error("Error while fetching vendor profile data")
    }
  }


  const getTextColor = (condition: any) => {
    return condition ? 'green' : 'black';
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (event: any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
    }
  };
  const [aboutBusinessName, setAboutBusinessName] = useState("");
  const [aboutBusinessNameError, setAboutBusinessNameError] = useState("");
  const [aboutName, setAboutName] = useState("");
  const [aboutNameError, setAboutNameError] = useState("");
  const [aboutBusinessType, setAboutBusinessType] = useState("");
  const [aboutBusinessTypeError, setAboutBusinessTypeError] = useState("");
  const [aboutPhone, setAboutPhone] = useState("");
  const [aboutPhoneError, setaboutPhoneError] = useState("");
  const [aboutTermsError, setaboutTermsError] = useState("");
  const validateAboutUsData = () => {
    let isValid = true;
    setAboutBusinessNameError("");
    setAboutNameError("")
    setAboutBusinessTypeError("");
    setaboutPhoneError("");
    setaboutTermsError("")
    if (aboutBusinessName === '') {
      setAboutBusinessNameError('please enter Your Business Name');
      isValid = false;
    }
    if (aboutName === '') {
      setAboutNameError('please enter Your Name');
      isValid = false;
    }
    if (aboutBusinessType === '') {
      setAboutBusinessTypeError('Please select Your Business Type');
      isValid = false;
    }

    if (aboutPhone === '') {
      setaboutPhoneError("Please enter Your Phone Number");
      isValid = false;
    } else if (aboutPhone.search(/[0-9]/) === -1) {
      setaboutPhoneError("Phone Number should be Numeric");
      isValid = false;
    } else if (aboutPhone.length < 10) {
      setaboutPhoneError("Phone Number must be 10 characters");
      isValid = false;
    }
    if (!isChecked) {
      setaboutTermsError("Please Accept Terms and Contitions");
      isValid = false;
    }
    if (isValid === true) {
      if (aboutBusinessType === '1') {
        setViewType(REGISTRATION_VIEW_TYPES.OTHER);
      } else {
        setViewType(REGISTRATION_VIEW_TYPES.OTHER);
      }
    }
  }
  const [serviceName, setServiceName] = useState("");
  const [serviceNameError, setServiceNameError] = useState("");
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [serviceCategory, setServiceCategory] = useState("");
  const [serviceCategoryError, setServiceCategoryError] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [serviceHoursError, setServiceHoursError] = useState("");
  const [serviceMinutes, setServiceMinutes] = useState("");
  const [serviceMinutesError, setServiceMinutesError] = useState("");
  const validateService = () => {
    let isValid = true;
    setServiceNameError("");
    setServiceTypeError("")
    setServiceCategoryError("");
    setServiceHoursError("");
    setServiceMinutesError("")
    // serviceName = serviceName.trim();
    if (serviceName === '') {
      setServiceNameError('please enter Your ServiceName');
      isValid = false;
    }
    if (serviceCategory === '') {
      setServiceCategoryError('Please select Your Service Category');
      isValid = false;
    }
    if (serviceMinutes === '') {
      setServiceMinutesError('Please select Your Service Minutes');
      isValid = false;
    }
    if (serviceHours === '') {
      setServiceHoursError('Please select Your Service Hours');
      isValid = false;
    }
    if (isValid === true) {
      if (aboutBusinessType === '1') {
        setViewType(REGISTRATION_VIEW_TYPES.OTHER);
      } else {
        setViewType(REGISTRATION_VIEW_TYPES.OTHER);
      }
    }
  }
  const [myPlace, setmyPlace] = useState(true);
  const [Client, setClient] = useState(false);
  const [workError, setworkError] = useState("");
  const handleMyPlaceChange = (e: any) => {
    setmyPlace(e.target.checked);
    console.log("clicked");
  };
  const handleClientChange = (e: any) => {
    setClient(e.target.checked);
    console.log("clicked");
  };
  const validateWork = () => {
    setworkError("");
    let isValid = true;
    if (myPlace === false && Client === false) {
      setworkError("Please check any one Options");
      isValid = false;
    }
    if (isValid === true) {
      setViewType(REGISTRATION_VIEW_TYPES.OTHER);
    }
  }
  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress1Error, setStreetAddress1Error] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [streetAddress2Error, setStreetAddress2Error] = useState("");
  const [country, setCountry] = useState("");
  const [countryError, setcountryError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const clearData = () => {
    setStreetAddress1("");
    setStreetAddress2("");
    setCountry("");
    setState("");
    setCity("");
    setPostCode("");
  };
  const validateAddress = () => {
    let isValid = true;
    setStreetAddress1Error("");
    setStreetAddress2Error("");
    setcountryError("");
    setStateError("");
    setCityError("");
    setPostCodeError("");
    if (streetAddress1 === '') {
      setStreetAddress1Error('Please enter your street address 1');
      isValid = false;
    }
    if (streetAddress2 === '') {
      setStreetAddress2Error('Please enter your street address 2');
      isValid = false;
    }
    if (country === '') {
      setcountryError('Please select your Country');
      isValid = false;
    }
    if (state === '') {
      setStateError('Please select your state');
      isValid = false;
    }
    if (city === '') {
      setCityError('Please select your City');
      isValid = false;
    }
    if (postCode === '') {
      setPostCodeError('Please select your Postcode');
      isValid = false;
    }
    if (isValid === true) {
      setViewType(REGISTRATION_VIEW_TYPES.OTHER);
    }
  }
  const validateBusinessTime = () => {
    alert("add Time Section");
  }

  const loginVendor = () => {
    console.log("Vendor LoggedIn");
  }
  const [selectedSections, setSelectedSections] = useState<number[]>([]);
  const [bselectedTitle, setbSelectedTitle] = useState<any>([]);
  const handlebTitleClick = (title: any) => {
    setbSelectedTitle((prevSelectedItems: any) => {
      const isSelected = prevSelectedItems.some(
        (selected: any) => selected.id === title.id
      );
      if (isSelected) {
        // Remove item if already selected
        return prevSelectedItems.filter(
          (selected: any) => selected.id !== title.id
        );
      } else if (prevSelectedItems.length < 5) {
        // Add item if not selected and less than 5 items are selected
        return [...prevSelectedItems, title];
      } else {
        // Do nothing if already selecting 5 items
        return prevSelectedItems;
      }
    });
  };

  const handleTitleClick = (index: any) => {
    // Check if the section is already selected
    if (selectedSections.includes(index)) {
      // Deselect the section
      setSelectedSections(selectedSections.filter(item => item !== index));
    } else {
      // Check if we can select more than 5 sections
      if (selectedSections.length < 5) {
        setSelectedSections([...selectedSections, index]);
      } else {
        alert('You can select up to 5 sections.');
      }
    }
  };
  const validatePassword = (password: string) => {
    const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.{8,})/;
    return passwordPattern.test(password);
  }
  const onInputChange = (type: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (type === 1) {
      setVendorProfileData({ ...vendorProfileData, email: value })
    }
    if (type === 2) {
      setVendorProfileData({ ...vendorProfileData, password: value })
    }
  }
  // Check Boxes
  const icon = (
    <Box
      sx={{
        width: 30,
        height: 30,
        backgroundColor: 'white',
        borderRadius: '8px',
        border: '1px solid #231F20'
      }}
    />
  );
  const checkedIcon = (
    <Box
      sx={{
        width: 30,
        height: 30,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#1976d2',
      }}
    >
      <img src={images.Checked} alt='Checked' style={{ width: 30, height: 30 }} />
    </Box>
  );
  // Dummy Data
  const premiumCategories = [
    {
      "id": 1,
      "name": "Nail Salon",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/nail-salon-66dac6764ead5.png",
      "sequence": 2,
      "is_premium_category": 1
    },
    {
      "id": 7,
      "name": "Hair Salon",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/hair-salon-66dac6764e81f.png",
      "sequence": 3,
      "is_premium_category": 1
    },
    {
      "id": 8,
      "name": "Brows & Lashes",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/eye-brow-66dac6764ab3f.png",
      "sequence": 4,
      "is_premium_category": 1
    },
    {
      "id": 9,
      "name": "Skin Care",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/skin-care-66dac6764ed8d.png",
      "sequence": 5,
      "is_premium_category": 1
    },
    {
      "id": 10,
      "name": "Aesthetic Medicine",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/aesthetic-medicine-66dac6e25c004.png",
      "sequence": 6,
      "is_premium_category": 1
    },
    {
      "id": 11,
      "name": "Barber",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/barber-66dac6764efd1.png",
      "sequence": 7,
      "is_premium_category": 1
    },
    {
      "id": 18,
      "name": "Braids & Locs",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 8,
      "is_premium_category": 2
    },
    {
      "id": 19,
      "name": "Hair Removal",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 9,
      "is_premium_category": 2
    },
    {
      "id": 20,
      "name": "Make up",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 10,
      "is_premium_category": 1
    },
    {
      "id": 21,
      "name": "Massage",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 11,
      "is_premium_category": 1
    },
    {
      "id": 22,
      "name": "Pet Services",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 12,
      "is_premium_category": 1
    },
    {
      "id": 23,
      "name": "Hair Color",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 17,
      "is_premium_category": 2
    },
    {
      "id": 24,
      "name": "Hair Cut",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 16,
      "is_premium_category": 2
    }
  ]
  // Default Lat Long
  const latLong = {
    lat: 23.027181141518188,
    lng: 72.48185665364666,
  }
  const mapContainerStyleAddress = {
    width: '100%',
    height: '150px',
  };
  // Radio Button
  const [selectedValue, setSelectedValue] = React.useState('option1');
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  // Dialog
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Service
  const [openService, setOpenService] = useState(false);
  const handleClickOpenService = () => {
    setOpenService(true);
  };
  const handleCloseService = () => {
    setOpenService(false);
  };
  // Vendor Categories Collection
  const vendorCategoriesCollection = (object: vendorCategoriesResData) => {
    if (selectedCategories && selectedCategories.length < 5) {
      let vendorCategoriesArray = [...selectedCategories];

      // Check if the object already exists in the array
      const index = vendorCategoriesArray.findIndex(
        (item) => item.id === object.id
      );

      if (index === -1) {
        // If object doesn't exist, add it
        vendorCategoriesArray.push(object);
      } else {
        // If object already exists, remove it
        vendorCategoriesArray.splice(index, 1);
      }

      console.log(vendorCategoriesArray, "vendorCategoriesArray");
      setSelectedCategories(vendorCategoriesArray);
    } else {
      toast.error("Max 5 categories can be selected")
    }
  }
  console.log(businessData, "businessData");
  console.log(vendorCategoriesData, "vendorCategoriesData");
  console.log(viewType, "viewType");
  console.log(selectedCategories, "selectedCategories");


  return (
    <Grid container height="100vh">
      <Grid
        item xs={12} sm={6}
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="loginFormBackground"
      >
        {viewType === REGISTRATION_VIEW_TYPES.LOGIN && (
          <Paper
            elevation={3}
            className="loginFormBox"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography className="loginTitle">Login</Typography>
            <Typography className="loginSubTitle">Enter your Email to login</Typography>
            <TextField
              placeholder="Email Address"
              variant="outlined"
              value={vendorProfileData.email}
              sx={{ mt: 3, width: '80%' }}
              error={!!formErrorData.email}
              helperText={formErrorData.email}
              onChange={(e) => setVendorProfileData({ ...vendorProfileData, email: e.target.value })}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                width: '80%',
                padding: '10px',
                fontSize: '16px',
                textTransform: "capitalize",
                fontWeight: "300",
              }}
              onClick={() => { checkEmail() }}
            >
              Continue With Email
            </Button>
            <Divider sx={{ width: '80%', mt: 3, mb: 3 }}><Typography variant="body1">OR</Typography></Divider>
            <Button
              variant="outlined"
              className="loginSocialButtons"
            >
              <img
                src={images.Google}
                alt="Google logo"
                style={{ width: 24, height: 24, marginRight: 8 }}
              />
              Continue with Google
            </Button>
            <Button
              variant="outlined"
              className="loginSocialButtons"
            >
              <img
                src={images.Facebook}
                alt="Google logo"
                style={{ width: 24, height: 24, marginRight: 8 }}
              />
              Continue with Facebook
            </Button>
          </Paper>
        )}

        {viewType === REGISTRATION_VIEW_TYPES.OTP_VERIFICATION && (
          <Paper
            elevation={3}
            className="loginFormBox otp"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.LOGIN) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">Verify Email</Typography>
              <Typography className="passwordSubTitle">Code has been sent to your email</Typography>
              <OtpInput
                value={vendorProfileData?.otp}
                onChange={(event: any) => {
                  handleVerificationCodeChange(event);
                }}
                numInputs={6}
                shouldAutoFocus
                renderInput={props => <input {...props} />}
                inputStyle={{
                  width: '50px',
                  height: '50px',
                  margin: '0 0.5rem',
                  borderRadius: '5px',
                }}
              />
              <small id="emailHelp" className="form-text text-danger">
                {formErrorData.otp}
              </small>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 8,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                  textTransform: "capitalize"
                }}
                disabled={vendorProfileData.otp && vendorProfileData.otp.length === 6 ? false : true}
                onClick={() => { vendorProfileData.otp ? verifyOTP(vendorProfileData?.otp?.toString()) : void (0) }}
              >
                Verify
              </Button>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px', marginLeft: "20px" }}>
                <span className='terms'>Didn't Receive Code ?</span>
                <span onClick={() => { vendorEmailSendOTP() }} className='termsLink' style={{ textDecoration: 'underline' }}>
                  Resend
                </span>
              </Typography>
            </Box>
          </Paper>
        )}

        {viewType === REGISTRATION_VIEW_TYPES.PASSWORD && (
          <Paper
            elevation={3}
            className="loginFormBox"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.LOGIN) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={3}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">Password</Typography>
              <Typography className="passwordSubTitle">It looks like there's already a profile
                associated with this email address</Typography>
              <TextField
                placeholder="Password"
                variant="outlined"
                sx={{ mt: 3, width: '80%' }}
                type={showPassword ? 'text' : 'password'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(2, event); }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className="forgotPasswordLink" onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.FORGOT_PASSWORD) }}>Forgot Password?</Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 8,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                }}
                onClick={() => { loginVendorFunc() }}
                disabled={
                  vendorProfileData?.password.match(/[a-zA-Z]/) &&
                    vendorProfileData?.password.match(/[0-9]/) &&
                    vendorProfileData?.password.length >= 8 ? false : true
                }
              >
                Continue
              </Button>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px', marginLeft: "20px" }}>
                <span className='terms'>By signing up I agree to the</span>
                <span className='termsLink'>
                  Terms & Conditions
                </span>
                <span className='terms'>and to the</span>
                <span className='termsLink'>
                  Privacy Policy
                </span>
              </Typography>
            </Box>
          </Paper>
        )}

        {viewType === REGISTRATION_VIEW_TYPES.FORGOT_PASSWORD && (
          <Paper
            elevation={3}
            className="loginFormBox"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.LOGIN) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={3}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">Forgot Password?</Typography>
              <Typography className="passwordSubTitle">Enter the email associated with your Glem9 account. </Typography>
              <TextField
                placeholder="Email Address"
                variant="outlined"
                sx={{ mt: 3, width: '80%' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(1, event) }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                }}
                onClick={() => { vendorForgotPasswordFunc() }}
                disabled={!isValidEmail(vendorProfileData.email) ? true : false}
              >
                Reset Password
              </Button>
            </Box>
          </Paper>
        )}

        {viewType === REGISTRATION_VIEW_TYPES.REGISTRATION_PASSWORD && (
          <Paper
            elevation={3}
            className="loginFormBox password"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.LOGIN) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={20}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">Password</Typography>
              <Typography className="passwordSubTitle">Enter the password for your business profile</Typography>
              <TextField
                placeholder="Password"
                variant="outlined"
                value={vendorProfileData.password}
                sx={{ mt: 3, width: { xl: '60%', md: '80%' } }}
                type={showPassword ? 'text' : 'password'}
                onChange={(event) => { setVendorProfileData({ ...vendorProfileData, password: event.target.value }) }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ marginTop: "20px", width: { xl: '60%', md: '80%' } }}>
                <Typography className="" sx={{ fontSize: "20px" }}>The password must contain</Typography>
                <br />
                <Typography className="passwordNote" style={{ color: getTextColor(vendorProfileData?.password.match(/[a-zA-Z]/)) }}>
                  <img
                    src={images.check}
                    alt="Check Icon"
                    style={{ width: 12, height: 12 }}
                  /> at least one letter
                </Typography>
                <Typography className="passwordNote" style={{ color: getTextColor(vendorProfileData?.password.match(/[0-9]/)) }}>
                  <img
                    src={images.check}
                    alt="Check Icon"
                    style={{ width: 12, height: 12 }}
                  /> at least one digit
                </Typography>
                <Typography className="passwordNote" style={{ color: getTextColor(vendorProfileData?.password.length >= 8) }}>
                  <img
                    src={images.check}
                    alt="Check Icon"
                    style={{ width: 12, height: 12 }}
                  /> 8 characters or more
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  width: { xl: '60%', md: '80%' },
                  padding: '10px',
                  mb: 2,
                  fontSize: '16px',
                  textTransform: 'capitalize'
                }}
                onClick={() => { registerVendorFunc() }}
                disabled={
                  vendorProfileData?.password.match(/[a-zA-Z]/) &&
                    vendorProfileData?.password.match(/[0-9]/) &&
                    vendorProfileData?.password.length >= 8 ? false : true

                }
              >
                Continue
              </Button>
            </Box>
          </Paper>
        )}

        {vendorCategoriesData && vendorCategoriesData?.length > 0 && viewType === REGISTRATION_VIEW_TYPES.BUSINESS_CATEGORIES ? (
          <Paper
            elevation={3}
            className="loginFormBox business"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={20}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              className="business_row"
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">What’s your business?</Typography>
              <Typography className="passwordSubTitle">Select the category you feel best represents your business</Typography>
              <Grid container sx={{ marginTop: "20px" }}>
                {vendorCategoriesData.map((row: vendorCategoriesResData, index: number) => {
                  if (row.is_premium_category === 1) {
                    return (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        key={index}
                        onClick={() => vendorCategoriesCollection(row)}
                        className={`circleImage ${bselectedTitle.some((item: any) => item === index)
                          ? "selected " + index
                          : ""
                          }`}
                        style={{ textAlign: 'center', marginBottom: "20px", cursor: 'pointer' }}

                      >
                        <img
                          src={row.logo_link}
                          alt="Category logo" // Update alt text for better accessibility
                          style={{
                            width: 30,
                            height: 30,
                            padding: 5,
                            borderRadius: "50%",
                            border: "1px #FAB93B solid"
                          }}
                        />
                        <Typography className="categoryTitleImage">{row.name}</Typography>
                      </Grid>
                    );
                  }
                  return null; // Return null if the condition is not met
                })}
              </Grid>
              <Grid container sx={{ marginTop: "10px" }}>
                <Grid
                  item xs={12} sm={12} style={{ textAlign: 'center', marginBottom: "20px" }}
                >
                  <Typography className="otherCategories">Other Categories</Typography>
                </Grid>
                {vendorCategoriesData.map((row, index) => {
                  if (row.is_premium_category === 2) {
                    return (
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        key={index}
                        onClick={() => vendorCategoriesCollection(row)}
                        className={`otherCategoriesTitleDiv ${bselectedTitle.some((item: any) => item === index)
                          ? "selected " + index
                          : ""
                          }`}
                        style={{ textAlign: 'center', marginBottom: "20px" }}
                      >
                        <Typography className="otherCategoriesTitle">{row.name}</Typography>
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 1,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                  textTransform: 'capitalize',
                }}
                onClick={() => setViewType(REGISTRATION_VIEW_TYPES.ABOUT_BUSINESS)}
              >
                Continue
              </Button>
            </Box>
          </Paper>
        ) : ""}

        {viewType === REGISTRATION_VIEW_TYPES.ABOUT_BUSINESS && (
          <Paper
            elevation={3}
            className="loginFormBox about"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.BUSINESS_CATEGORIES) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={40}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">About You</Typography>
              <Typography className="passwordSubTitle">Tell us more about yourself and your business</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                {/* First Row: Two Half-Width Text Boxes */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    className='inputFormAbout'
                    fullWidth
                    label="Business Name"
                    variant="outlined"
                    value={businessData?.business_name}
                    required
                    onChange={(e) => { if (e?.target?.value) { setBusinessData({ ...businessData, business_name: e.target.value }) } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Your Name"
                    variant="outlined"
                    required
                    value={businessData?.owner_name}
                    onChange={(e) => { if (e?.target?.value) { setBusinessData({ ...businessData, owner_name: e.target.value }) } }}
                  />
                </Grid>
                {/* Second Row: Full Width Text Box */}
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" error={!!aboutBusinessTypeError}>
                    <InputLabel id="dropdown-label">Select Business Type</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      required
                      value={businessData?.business_type}
                      onChange={e => {
                        if (e?.target?.value) {
                          let businessType = e?.target?.value ? e?.target?.value : 0
                          setBusinessData({ ...businessData, business_type: businessType })
                        }

                      }}
                    >
                      {vendorCategoriesData && vendorCategoriesData.length > 0 && vendorCategoriesData.map((row: any, index: number) => {
                        return (
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    required
                    value={businessData?.phone_number}
                    onChange={(e) => { if (e.target.value) { setBusinessData({ ...businessData, phone_number: e.target.value }) } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Flag code="IN" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="+91 "
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={!!aboutTermsError}>
                    <FormControlLabel
                      onChange={(e: any) => { setBusinessData({ ...businessData, is_terms: e.target.checked }) }}
                      control={
                        <Checkbox name="" />
                      }
                      label="I accept the Terms and Conditions and confirm that I have read the Privacy Policy"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    onChange={(e: any) => { setBusinessData({ ...businessData, is_notification: e.target.checked }) }}
                    control={
                      <Checkbox

                      />
                    }
                    label="I want to receive information about Glam9 promotions and services, including business tips."
                  />
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 4,
                    width: '80%',
                    padding: '10px',
                    fontSize: '16px',
                    marginLeft: "50px",
                    textTransform: 'capitalize'
                  }}
                  onClick={() => { validateAboutUsData() }}
                >
                  Continue
                </Button>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox work"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={45}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle"> Where do you work, Salon?</Typography>
              <Typography className="passwordSubTitle">Do your clients come to you, do you go to them, or both?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={myPlace}
                        className='myPlace'
                        onChange={handleMyPlaceChange}
                        name="newsletters"
                        icon={icon}
                        checkedIcon={checkedIcon}
                      />
                    }
                    label={
                      <Box>
                        <Typography className='checkBoxLabel' variant="body1">
                          At my place
                        </Typography>
                        <Typography className='checkBoxSubLabel' variant="body2" color="text.secondary">
                          I accept the Terms and Conditions and confirm that I have read the Privacy Policy
                        </Typography>
                      </Box>
                    }
                  />

                </Grid>
                <Grid item xs={12}>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Client}
                        onChange={handleClientChange}
                        name="newsletters"
                        icon={icon}
                        checkedIcon={checkedIcon}
                      />
                    }
                    label={
                      <Box>
                        <Typography className='checkBoxLabel' variant="body1">
                          At the client's location
                        </Typography>
                        <Typography className='checkBoxSubLabel' variant="body2" color="text.secondary">
                          I'm on the go. My services are performed at the client's location.
                        </Typography>
                      </Box>
                    }
                  />
                </Grid>
                <Typography className='' sx={{ color: "red", fontSize: "16px" }} variant="body1">{workError}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 4,
                    width: '80%',
                    padding: '10px',
                    fontSize: '16px',
                    marginLeft: "50px",
                    textTransform: 'capitalize'
                  }}
                  onClick={validateWork}
                >
                  Continue
                </Button>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox search"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={60}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">  Enter Your Address</Typography>
              <Typography className="passwordSubTitle">Where can clients find you?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                <Grid item xs={12}>
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    sx={{ mt: 3, width: '80%' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(2, event); }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {<SearchIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ height: "200px" }}>
                </Grid>
                <Grid item xs={12}>
                  <Grid container >
                    <Grid item xs={6}>
                      <Typography className="searchDesc">Can't find your address?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 4,
                          width: '80%',
                          padding: '10px',
                          fontSize: '16px',
                          marginLeft: "50px",
                          marginTop: "-10px",
                          textTransform: 'capitalize'
                        }}
                        onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox confirm"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={60}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">Confirm Your Address</Typography>
              <Typography className="passwordSubTitle">Where can clients find you?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                {/* First Row: Two Half-Width Text Boxes */}
                <Grid item xs={12} sm={12}>
                  <TextField
                    className='inputFormAbout'
                    fullWidth
                    label="Street Address Line 1"
                    variant="outlined"
                    required
                    value={streetAddress1}
                    error={!!streetAddress1Error} // Boolean for error prop
                    helperText={streetAddress1Error}
                    onChange={(e) => setStreetAddress1(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    className='inputFormAbout'
                    fullWidth
                    label="Street Address Line 2"
                    variant="outlined"
                    required
                    value={streetAddress2}
                    error={!!streetAddress2Error} // Boolean for error prop
                    helperText={streetAddress2Error}
                    onChange={(e) => setStreetAddress2(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" error={!!countryError}>
                    <InputLabel id="dropdown-label">Country</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      value={country}
                      className='country'
                      required
                      onChange={e => {
                        setCountry(e.target.value as string);
                      }}
                    >
                      <MenuItem value={1}>Option 1</MenuItem>
                      <MenuItem value={2}>Option 2</MenuItem>
                      <MenuItem value={3}>Option 3</MenuItem>
                    </Select>
                    {countryError && <FormHelperText>{countryError}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" error={!!stateError}>
                    <InputLabel id="dropdown-label">State</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      value={state}
                      className='state'
                      required
                      onChange={e => {
                        setState(e.target.value as string);
                      }}
                    >
                      <MenuItem value={1}>Option 1</MenuItem>
                      <MenuItem value={2}>Option 2</MenuItem>
                      <MenuItem value={3}>Option 3</MenuItem>
                    </Select>
                    {stateError && <FormHelperText>{stateError}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" error={!!cityError}>
                    <InputLabel id="dropdown-label">City</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      required
                      value={city}
                      className='city'
                      onChange={e => {
                        setCity(e.target.value as string);
                      }}
                    >
                      <MenuItem value={1}>Option 1</MenuItem>
                      <MenuItem value={2}>Option 2</MenuItem>
                      <MenuItem value={3}>Option 3</MenuItem>
                    </Select>
                    {cityError && <FormHelperText>{cityError}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    className='inputFormAbout'
                    fullWidth
                    label="Passcode"
                    variant="outlined"
                    required
                    value={postCode}
                    error={!!postCodeError} // Boolean for error prop
                    helperText={postCodeError}
                    onChange={(e) => setPostCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className='buttonNo'
                    sx={{
                      mt: 4,
                      width: '99%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      background: "white",
                      color: "#0178FA",
                      boxShadow: "none",
                      border: '1px solid #0178FA',
                      textTransform: 'capitalize'
                    }}
                    onClick={clearData}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '99%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      textTransform: 'capitalize'
                    }}
                    onClick={validateAddress}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox workHere"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={65}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle"> Do You Work Here?</Typography>
              <Typography className="passwordSubTitle">If so we'll link your Galm9 profile to this Shared Location.
                Clients will be able to find you and book you through
                either profile page.</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", }}>
                <Grid item xs={12}>
                  {latLong && process.env.REACT_APP_GOOGLE_MAPS_API_KEY && (
                    <LoadScript
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    >
                      <GoogleMap
                        mapContainerStyle={mapContainerStyleAddress}
                        zoom={10}
                        center={latLong}
                        mapContainerClassName='googleMap'
                      >
                        <Marker position={latLong} />
                      </GoogleMap>
                    </LoadScript>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 4,
                      width: '99%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      textTransform: 'capitalize'
                    }}
                    onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox teamsize"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={65}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle"> What’s your team size?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="options"
                    value={selectedValue}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      value="option1"
                      control={<Radio />}
                      label="Just me"
                    />
                    <FormControlLabel
                      value="option2"
                      control={<Radio />}
                      label="2-3 staff members"
                    />
                    <FormControlLabel
                      value="option3"
                      control={<Radio />}
                      label="4-6 staff members"
                    />
                    <FormControlLabel
                      value="option4"
                      control={<Radio />}
                      label="More than 6 staff members"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '80%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      textTransform: 'capitalize'
                    }}
                    onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox businessHours"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={65}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle"> Your Business Hours</Typography>
              <Typography className="passwordSubTitle">Where can clients find you?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                {[...Array(7)].map((_, index) => (
                  <Grid item xs={12} sm={12} sx={{ marginLeft: { xl: '50px', sm: '0px', md: '0px' }, paddingTop: "10px" }} >
                    <Grid container>
                      <Grid item xs={1}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch onChange={() => { console.log("Switch Clicked"); }} />
                          }
                          label=""
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className='businessDay'>Monday</Typography>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={3}>
                        <Typography className='businessTime'>10:00 - 12:00</Typography>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={2}>
                        <ArrowForwardIosIcon fontSize="small" onClick={handleClickOpen} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '80%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      marginTop: "5px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox service"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={65}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle"> Start Adding Services</Typography>
              <Typography className="passwordSubTitle">Add at least one service now. Later you can add more,
                edit details, and group services into categories.</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                {[...Array(4)].map((_, index) => (
                  <Grid item xs={12} sm={12} style={{ marginLeft: "0px", marginTop: "10px" }}>
                    <Grid container>
                      <Grid item xs={1}>
                        <img src={images.Cancel} alt='cancel' style={{ height: 15, width: 15 }} />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className='serviceTextLabel'>Hair Cut</Typography>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={2}>
                        <Typography className='serviceTextDuration'>30 min</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className='serviceTextCurrency'>₹30.00</Typography>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={1}>
                        <ArrowForwardIosIcon fontSize="small" onClick={handleClickOpenService} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "20px", marginLeft: "0px", textAlign: "left" }}>
                  <Typography onClick={handleClickOpenService} className='serviceTextAction'>+ Add Service</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '100%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      textTransform: "capitalize",
                      marginTop: "-5px"
                    }}
                    onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox help"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={80}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">  How do you hope Glam9 can help you?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                <Grid item xs={12} sm={12} style={{ marginLeft: "0px", marginTop: "20px", marginBottom: "20px", textAlign: "left" }}>
                  <Typography className="glamHelp"> Choose Upto 5</Typography>
                </Grid>
                {[...Array(10)].map((_, index) => (
                  <Grid
                    key={index}
                    className={`glamHelpLabelBox ${selectedSections.includes(index) ? 'selected' : ''}`}
                    item xs={6} sm={6} md={6} lg={6} xl={6}
                    style={{ marginTop: "10px", paddingLeft: '0px', cursor: 'pointer' }}
                  >
                    <Typography
                      onClick={() => handleTitleClick(index)}
                      className={`glamHelpBoxContent ${selectedSections.includes(index) ? 'selected' : ''}`}
                    >
                      Attract New Clients
                    </Typography>
                  </Grid>
                ))}
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '80%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                      marginTop: "-5px",
                      textTransform: "capitalize"
                    }}
                    onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox help"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mt: 2,
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                color="primary"
                onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
              >
                <img
                  src={images.leftArrow}
                  alt="Back arrow"
                  style={{ width: 40, height: 40 }}
                />
              </IconButton>
              <Box sx={{ flex: 1, ml: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={95}
                  sx={{
                    height: 10,
                    width: "80%",
                    backgroundColor: '#EEEEEE',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#FAB93B',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">  Have you previously used
                other tools to support
                your business?</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="options"
                    value={selectedValue}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      value="option1"
                      control={<Radio />}
                      label="Yes, I have"
                    />
                    <FormControlLabel
                      value="option2"
                      control={<Radio />}
                      label="No, I haven’t"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '80%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                    }}
                    onClick={goToFinalPage}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        {viewType === REGISTRATION_VIEW_TYPES.OTHER && isNewProfile && (
          <Paper
            elevation={3}
            className="loginFormBox"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: "0 auto",
                marginTop: "20px"
              }}
            >
              <Typography className="passwordTitle">You're All Set</Typography>
              <Typography className="passwordSubTitle">Welcome to the best tool for managing
                your Business</Typography>
              <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
                <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
                  <Typography className="passwordSubTitle">Glam9 Biz Pro is designed to help you keep peace. Manage team calendars, create Shifts, process payments, run reports, and check stock levels - all from the front desk.
                    And, when you're on the go? Use Glam9 Biz on your mobile devices to access core features and keep tabs on your business from anywhere.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 4,
                      width: '80%',
                      padding: '10px',
                      fontSize: '16px',
                      marginLeft: "0px",
                    }}
                    onClick={() => { setViewType(REGISTRATION_VIEW_TYPES.OTHER) }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
      </Grid>
      <Grid
        className='logo'
        item xs={12} sm={6}
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={images.LogoBg}
          alt="Login Logo"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            backgroundColor: 'black',
          }}
        />
        <Box
          component="img"
          src={images.Logo}
          alt="Login Logo"
          sx={{
            objectFit: 'cover',
            position: 'absolute',
            width: {
              xs: '11px',
              sm: '25%',
              md: '25%',
              lg: '25%',
              xl: '25%',
            },
          }}
        />
      </Grid>
      {/* Time Add */}
      <Dialog open={open} onClose={handleClose} style={{ borderRadius: "100px" }}>
        <DialogContent>
          <DialogContentText>
            <Typography className="passwordTitle"> Monday</Typography>
            <Typography className="passwordSubTitle"> Set your business hours here. Head to your calendar
              if you need to adjust hours for a single day.</Typography>
          </DialogContentText>
          <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
            <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='popupTimeText'>Opening Hours</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="dropdown-label"
                      required
                      value={"10:00"}
                    >
                      <MenuItem value={"10:00"}>10:00</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="dropdown-label"
                      value={"10:00"}
                      required
                    >
                      <MenuItem value={"10:00"}>10:00</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
              <Grid container>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="dropdown-label"
                      required
                      value={"10:00"}
                    >
                      <MenuItem value={"Closed"}>Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="dropdown-label"
                      required
                      value={"10:00"}
                    >
                      <MenuItem value={"10:00"}>10:00</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      labelId="dropdown-label"
                      value={"10:00"}
                      required
                    >
                      <MenuItem value={"10:00"}>10:00</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography className='popupTimeTextAction'>+ Add Slot</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{ marginBottom: "20px", textAlign: 'center' }}><Grid item xs={6}>
            <Button
              variant="contained"
              sx={{
                mt: 4,
                width: '80%',
                padding: '10px',
                fontSize: '16px',
                marginLeft: "20px",
                marginTop: "10px"
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                  marginLeft: "20px",
                  marginTop: "10px"
                }}
                onClick={validateBusinessTime}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* Service Add */}
      <Dialog open={openService} onClose={handleCloseService} style={{ borderRadius: "100px" }}>
        <DialogContent>
          <DialogContentText>
            <Typography className="passwordTitle">  Service Details</Typography>
            <Typography className="passwordSubTitle"> Add the basic information for this service now.
              You'll be able to add a description and adjust
              the advanced settings for this service later on.</Typography>
          </DialogContentText>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            {/* First Row: Two Half-Width Text Boxes */}
            <Grid item xs={12} sm={12}>
              <TextField
                className='inputFormAbout'
                fullWidth
                label="Service Name"
                variant="outlined"
                required
                value={serviceName}
                error={!!serviceNameError} // Boolean for error prop
                helperText={serviceNameError}
                onChange={(e) => setServiceName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" error={!!serviceTypeError}>
                <InputLabel id="dropdown-label">Service Type</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Option"
                  onChange={e => {
                    setServiceTypeError(e.target.value as string);
                  }}
                  required
                >
                  <MenuItem value={1}>Option 1</MenuItem>
                  <MenuItem value={2}>Option 2</MenuItem>
                  <MenuItem value={3}>Option 3</MenuItem>
                </Select>
                {serviceTypeError && <FormHelperText>{serviceTypeError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" error={!!serviceCategoryError}>
                <InputLabel id="dropdown-label">Service Category</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Option"
                  required
                  onChange={e => {
                    setServiceCategory(e.target.value as string);
                  }}
                >
                  <MenuItem value={1}>Option 1</MenuItem>
                  <MenuItem value={2}>Option 2</MenuItem>
                  <MenuItem value={3}>Option 3</MenuItem>
                </Select>
                {serviceCategoryError && <FormHelperText>{serviceCategoryError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" error={!!serviceHoursError}>
                <InputLabel id="dropdown-label">Hours</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Option"
                  required
                  onChange={e => {
                    setServiceHours(e.target.value as string);
                  }}
                >
                  <MenuItem value={1}>Option 1</MenuItem>
                  <MenuItem value={2}>Option 2</MenuItem>
                  <MenuItem value={3}>Option 3</MenuItem>
                </Select>
                {serviceHoursError && <FormHelperText>{serviceHoursError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" error={!!serviceMinutesError}>
                <InputLabel id="dropdown-label">Minutes</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Option"
                  required
                  onChange={e => {
                    setServiceMinutes(e.target.value as string);
                  }}
                >
                  <MenuItem value={1}>Option 1</MenuItem>
                  <MenuItem value={2}>Option 2</MenuItem>
                  <MenuItem value={3}>Option 3</MenuItem>
                </Select>
                {serviceMinutesError && <FormHelperText>{serviceMinutesError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className='inputFormAbout'
                fullWidth
                label="Amount"
                variant="outlined"
                value={"45.00"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      ₹
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                className='inputFormAbout'
                fullWidth
                label="Start At"
                variant="outlined"
                            />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{ marginBottom: "20px", textAlign: 'center' }}><Grid item xs={6}>
            <Button
              variant="contained"
              color='error'
              sx={{
                mt: 4,
                width: '80%',
                padding: '10px',
                fontSize: '16px',
                marginLeft: "20px",
                marginTop: "10px"
              }}
              onClick={handleCloseService}
            >
              Remove
            </Button>
          </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  width: '80%',
                  padding: '10px',
                  fontSize: '16px',
                  marginLeft: "20px",
                  marginTop: "10px"
                }}
                onClick={validateService}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default Login;
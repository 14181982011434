import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Blue color for primary
    },
    secondary: {
      main: "#dc004e", // Red color for secondary
    },
    text: {
      primary: "#231F20", // Defining black for text color
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif", // Defining the font family
    h1: {
      fontSize: "60px", // Font size for h1 or heading type
      fontWeight: 300, // Adjusted to the nearest valid value
      lineHeight: "normal", // Normal line height
    },
    body1: {
      fontSize: "16px", // Example for body text
      color: "#231F20", // Reference color from palette or directly use hex
    },
  },
});

export default theme;

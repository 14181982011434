// src/api/userApi.ts
import { apiClient } from "./apiClient";
import { ApiResponse, commonDataListResponse } from "./types/common";
import { ValidateEmailResponse, emailSendOTPResponse, loginVendorReqData, registerVendorReqData, registerVendorResponse, vendorCategoriesResponse, vendorLoginDataResponse, vendorProfileDataResponse } from "./types/vendor";
// Validate Email
export const validateEmail = (emailID: string) => {
  return apiClient<ValidateEmailResponse>(
    `/check-vendor-user`,
    { method: "POST", body: { email: emailID } },
  );
};
// Send Email Verification OTP
export const sendEmailVerificationOTP = (emailID: string) => {
  return apiClient<emailSendOTPResponse>(
    `/send-vendor-otp`,
    { method: "POST", body: { email: emailID } },
  );
};
// Verify OTP
export const verifyOTP = (emailID: string) => {
  return apiClient<ApiResponse>(
    `/verify-vendor-otp`,
    { method: "POST", body: { email: emailID, is_otp_verified: 1 } },
  );
};
// Register Vendor
export const registerVendor = (data: registerVendorReqData) => {
  return apiClient<registerVendorResponse>(
    `/vendor-registration`,
    { method: "POST", body: data },
  );
};
// Get Vendor Profile Data
export const getVendorProfileData = () => {
  return apiClient<vendorProfileDataResponse>(
    `/get-vendor-profile`,
    { method: "POST", body: {} },
  );
};
export const getStateList = (countryId: number) => {
  return apiClient<ApiResponse>(`/get-location?countryId=${countryId}`, {
    method: 'GET',
  });
};

// Get City List
export const getCityList = (stateId: number, countryId: number) => {
  return apiClient<ApiResponse>(
    `/get-location?stateId=${stateId}&countryId=${countryId}`,
    { method: 'GET' },
  );
};
// Login Vendor
export const loginVendor = (data: loginVendorReqData) => {
  return apiClient<vendorLoginDataResponse>(
    `/vendor-login`,
    { method: "POST", body: data },
  );
};
// Forgot Password
export const forgotPassword = (email: string) => {
  return apiClient<ApiResponse>(
    `/vendor-forgot-password`,
    { method: "POST", body: { email: email } },
  );
};
// Vendor Categories
export const vendorCategories = () => {
  return apiClient<vendorCategoriesResponse>(
    `/get-categories?use_for=1`,
    { method: "GET", },
  );
};
// Get Common Data List
export const commonDataList = () => {
  return apiClient<commonDataListResponse>(
    `/get-dropdown-list`,
    { method: "GET", },
  );
};
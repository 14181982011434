import { ApiResponse } from "./common"
export interface vendorProfileData {
  email: string
  password: string
  otp?: string
}
// Registration View Types
export enum REGISTRATION_VIEW_TYPES {
  LOGIN = "LOGIN",
  OTHER = "OTHER",
  OTP_VERIFICATION = "OTP_VERIFICATION",
  REGISTRATION_PASSWORD = "REGISTRATION_PASSWORD",
  BUSINESS_CATEGORIES = "BUSINESS_CATEGORIES",
  PASSWORD = "PASSWORD",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  ABOUT_BUSINESS = "ABOUT_BUSINESS"
}
// Registration Validation Errors
export interface registrationValidationErrors {
  email?: string,
  otp?: string
}
// Validate Email Data And Response
export interface ValidateEmailData {
  is_available: number
  is_approved: number
  email_verified: number
}
export interface ValidateEmailResponse extends ApiResponse<ValidateEmailData | null> { }
// Send Email OTP Response
export interface emailSendOTPResData {
  otp: number
  is_available: number
}
export interface emailSendOTPResponse extends ApiResponse<emailSendOTPResData | null> { }
// Register Vendor Request
export interface registerVendorReqData {
  email: string
  password: string
  device_token?: string
  device_uuid?: string
  device_os?: string
  device_type?: number
  device_name?: string
  time_zone?: string
}
// Register Vendor Response
export interface registerVendorResData {
  vendor_id: string
  vendor_detail_id: string
  vendor_code: string
  email: string
  account_type: string
  time_zone: string
  email_verified: string
  profile_flow_screen: string
  profile_complete_percentage: string
  created_date: string
  token: string
}
export interface registerVendorResponse extends ApiResponse<registerVendorResData | null> { }
// Vendor Profile Data Response
export interface vendorProfileInfo {
  vendor_id: string
  user_name: string
  email: string
  mobile: string
  DOB: string
  age: string
  full_mobile: string
  vendor_code: string
  account_type: string
  social_id: string
  time_zone: string
  email_verified: string
  profile_image: string
  profile_flow_screen: string
  profile_complete_percentage: string
  created_date: string
  joined_date: string
  vendorInformation: any[]
}
export interface vendorProfileDataResponse extends ApiResponse<vendorProfileInfo | null> { }
// Login Vendor Request
export interface loginVendorReqData {
  email: string
  password: string
  device_token?: string
  device_uuid?: string
  device_id?: string
  device_os?: string
  device_type?: number
  device_name?: string
  time_zone?: string
}
export interface loginVendorResData {
  vendor_id: string
  vendor_detail_id: string
  vendor_code: string
  email: string
  account_type: string
  time_zone: string
  email_verified: string
  is_approved: string
  profile_flow_screen: string
  profile_complete_percentage: string
  created_date: string
  token: string
}
export interface vendorLoginDataResponse extends ApiResponse<loginVendorResData | null> { }
// Vendor Categories
export interface vendorCategoriesResData {
  id: number
  name: string
  logo_link: string
  sequence: number
  is_premium_category: number
}
export interface vendorCategoriesResponse extends ApiResponse<vendorCategoriesResData | null> { }
// Vendor Business Data
export interface vendorBusinessData {
  business_name?: string
  owner_name?: string
  business_type?: number
  phone_number?: string
  is_notification_enabled?: boolean
}